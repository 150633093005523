import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 手机空间瘦身
  // {
  //   path: '/',
  //   name: 'PhoneHome',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  // },
  // 安心清理专家
  // {
  //   path: '/',
  //   name: 'CleanHome',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/Clean.vue')
  // },
  // 安心文件管理
  // {
  //   path: '/',
  //   name: 'FileHome',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/AxFile.vue')
  // },
  // 官网首页
  // {
  //   path: '/',
  //   name: 'OfficialPage',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/official')
  // },
  // 扫描大师
  // {
  //   path: '/',
  //   name: 'Axsm',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/axsm.vue')
  // },
  // {
  //   path: '/validateCode',
  //   name: 'ValidateCode',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/validateCode')
  // }
  // 安心清理极速版
  // {
  //   path: '/',
  //   name: 'AxCleanBrowser',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/axCleanBrowser')
  // }
  // Ease cleaner
  {
    path: '/',
    name: 'EaseCleaner',
    component: () => import(/* webpackChunkName: "home" */ '../views/easeCleaner')
  }
  // 疯看视频
  // {
  //   path: '/',
  //   name: 'FkVideo',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/fkVideo')
  // },
  // 天神清理
  // {
  //   path: '/',
  //   name: 'Godclean',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/godclean')
  // }
]

const router = new VueRouter({
  routes
})

export default router
